// main.js
import { state } from './state.js';
import { renderTeamList, renderInteractionList, showStep } from './uiManager.js';
import { drawGraph } from './graphManager.js';
import { inject } from "@vercel/analytics";


const STEPS = {
    ADD_TEAMS: 'step1',
    DEFINE_INTERACTIONS: 'step2',
    VIEW_GRAPH: 'step3'
};

document.addEventListener('DOMContentLoaded', () => {
    console.log('DOM fully loaded');

    // Load the initial state
    state.loadFromStorage();
    console.log('Initial state loaded:', state.getTeams());

    // Set up the state change listener
    state.addListener(() => {
        console.log('State changed, updating UI');
        renderTeamList();
        renderInteractionList();
        if (document.getElementById(STEPS.VIEW_GRAPH).classList.contains('hidden') === false) {
            drawGraph();  // Redraw the graph if it's currently visible
        }
        state.saveToStorage();
    });

    // Set up UI event listeners
    document.getElementById('addTeamsBtn').addEventListener('click', () => showStep(STEPS.ADD_TEAMS));
    document.getElementById('defineInteractionsBtn').addEventListener('click', () => showStep(STEPS.DEFINE_INTERACTIONS));
    document.getElementById('viewGraphBtn').addEventListener('click', () => showStep(STEPS.VIEW_GRAPH));

    document.getElementById('addTeamForm').addEventListener('submit', (e) => {
        e.preventDefault();
        const fullNameInput = document.getElementById('teamFullName');
        const shortNameInput = document.getElementById('teamShortName');
        const descriptionInput = document.getElementById('teamDescription');

        if (fullNameInput && shortNameInput) {
            const fullName = fullNameInput.value.trim();
            const shortName = shortNameInput.value.trim();
            const description = descriptionInput ? descriptionInput.value.trim() : '';

            if (fullName && shortName) {
                if (state.addTeam(fullName, shortName, description)) {
                    fullNameInput.value = '';
                    shortNameInput.value = '';
                    if (descriptionInput) descriptionInput.value = '';
                    console.log('Team added:', fullName, shortName);
                } else {
                    console.log('Failed to add team:', fullName, shortName);
                }
            }
        } else {
            console.error('Team input fields not found');
        }
    });

    document.getElementById('teamSelect').addEventListener('change', renderInteractionList);

    // Initial renders
    console.log('Performing initial renders');
    renderTeamList();
    renderInteractionList();
});

// Analytics
inject()

// Expose some functions to the global scope for debugging
window.debugState = () => console.log(state.getTeams(), state.getInteractions());
