import { state } from './state.js';
import { getSvgIcon } from './iconManager.js';

const interactionTypes = [
    { value: 'none', label: 'None', color: '#999999', icon: '' },
    { value: 'collaboration', label: 'Collaboration', color: '#FF6B6B', icon: 'collaboration' },
    { value: 'x-as-a-service', label: 'X-as-a-Service', color: '#4ECDC4', icon: 'x-as-a-service' },
    { value: 'facilitation', label: 'Facilitation', color: '#FFA500', icon: 'facilitation' }
];

class InteractionItem extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
    }

    connectedCallback() {
        this.render();
    }

    static get observedAttributes() {
        return ['team1', 'team2'];
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (oldValue !== newValue) {
            this.render();
        }
    }

    render() {
        const team1 = this.getAttribute('team1');
        const team2 = this.getAttribute('team2');
        const currentInteraction = getInteraction(team1, team2);

        this.shadowRoot.innerHTML = `
            <style>
                :host {
                    display: block;
                    margin-bottom: 1rem;
                    font-family: inherit;
                }
                .interaction-container {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                }
                .team-name {
                    font-weight: bold;
                    font-size: 1rem;
                    margin-bottom: 0.5rem;
                }
                .interaction-buttons {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 0.5rem;
                }
                .interaction-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0.5rem;
                    border: none;
                    border-radius: 0.25rem;
                    font-size: 0.875rem;
                    cursor: pointer;
                    transition: opacity 0.2s;
                    white-space: nowrap;
                    flex: 1 1 calc(25% - 0.5rem);
                    min-width: 120px;
                }
                .interaction-btn:hover {
                    opacity: 0.8;
                }
                .interaction-btn.active {
                    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
                    font-weight: bold;
                }
                .interaction-btn svg {
                    width: 1rem;
                    height: 1rem;
                    margin-right: 0.25rem;
                }
                @media (max-width: 640px) {
                    .interaction-btn {
                        flex: 1 1 calc(50% - 0.5rem);
                    }
                }
            </style>
            <div class="interaction-container">
                <span class="team-name">${team2}</span>
                <div class="interaction-buttons">
                    ${interactionTypes.map(type => `
                        <button class="interaction-btn ${currentInteraction === type.value ? 'active' : ''}"
                                style="background-color: ${type.color}; color: white;"
                                data-value="${type.value}">
                            ${type.icon ? getSvgIcon(type.icon) : ''}
                            ${type.label}
                        </button>
                    `).join('')}
                </div>
            </div>
        `;

        this.shadowRoot.querySelector('.interaction-buttons').addEventListener('click', (e) => {
            if (e.target.closest('.interaction-btn')) {
                const button = e.target.closest('.interaction-btn');
                state.updateInteraction(team1, team2, button.dataset.value);
                this.render();
            }
        });
    }
}

customElements.define('interaction-item', InteractionItem);

export function renderInteractionList() {
    const teams = state.getTeams();
    const selectedTeam = document.getElementById('teamSelect').value;
    const interactionList = document.getElementById('interactionList');

    interactionList.innerHTML = teams
        .filter(team => team.shortName !== selectedTeam)
        .map(team => `<interaction-item team1="${selectedTeam}" team2="${team.shortName}"></interaction-item>`)
        .join('');
}

export function getInteraction(team1, team2) {
    const interactions = state.getInteractions();
    return interactions[team1] && interactions[team1][team2] ? interactions[team1][team2] : 'none';
}

export function getInteractionColor(type) {
    const interactionType = interactionTypes.find(t => t.value === type);
    return interactionType ? interactionType.color : '#999999';
}

export function getInteractionIcon(type) {
    const interactionType = interactionTypes.find(t => t.value === type);
    return interactionType ? interactionType.icon : '';
}
