import { state } from './state.js';
import { getInteractionColor } from './interactionManager.js';
import { getSvgIcon } from './iconManager.js';

// Configurable constants
const config = {
    width: 800,
    height: 600,
    radius: 30,  // Node size
    forceStrength: -1000,
    distance: 200,
    zoomExtent: [0.5, 2] // Min and Max zoom levels
};

/**
 * Appends an interaction icon (circle and SVG) to the given D3 group element.
 * @param {object} g - The D3 group selection.
 * @param {string} type - The interaction type.
 * @param {number} [x=0] - The x-offset for the icon position.
 */
function appendIcon(g, type, x = 0) {
    // Append a circle to hold the icon
    g.append("circle")
        .attr("r", 15)  // Circle size for all icons
        .attr("cx", x)
        .attr("fill", getInteractionColor(type));

    const iconSvg = getSvgIcon(type);
    if (iconSvg) {
        // Append the SVG content inside the circle using foreignObject
        g.append("foreignObject")
            .attr("x", x - 9)  // Adjusted to fit inside the circle
            .attr("y", -9)
            .attr("width", 24)
            .attr("height", 24)
            .html(iconSvg);  // Insert the SVG icon
    }
}


/**
 * Creates an icon group for a link, handling cases with interaction disagreements.
 * @param {object} g - The D3 group selection.
 * @param {string} type1 - The first interaction type.
 * @param {string} type2 - The second interaction type.
 */
function createIconGroup(g, type1, type2) {
    const x = -10;
    if (type1 !== 'none' && type2 !== 'none' && type1 !== type2) {
        // Handle disagreement case by appending both icons
        appendIcon(g, type1, x);
        appendIcon(g, type2, -x);
    } else {
        const type = type1 !== 'none' ? type1 : type2;
        appendIcon(g, type);
    }
}

/**
 * Handles the visual representation of interaction disagreements.
 * @param {object} link - The D3 selection of the link.
 * @param {string} type1 - First team's interaction view.
 * @param {string} type2 - Second team's interaction view.
 */
function handleDisagreement(link, type1, type2) {
    if (type1 !== type2 && type1 !== 'none' && type2 !== 'none') {
        link.attr("stroke", "#FF0000") // Red to highlight disagreement
            .attr("stroke-dasharray", "5,5") // Dashed line for disagreement
            .attr("stroke-width", 4); // Thicker line for disagreement
    } else {
        link.attr("stroke", getInteractionColor(type1 !== 'none' ? type1 : type2))
            .attr("stroke-width", 3);
    }
}

/**
 * Draws the graph with the specified nodes, links, and interactions.
 */
export function drawGraph() {
    const svg = d3.select("#graph")
        .attr("viewBox", `0 0 ${config.width} ${config.height}`)
        .call(d3.zoom()
            .scaleExtent(config.zoomExtent)
            .on("zoom", (event) => {
                graphGroup.attr("transform", event.transform);
            }));

    svg.selectAll("*").remove();

    const { width, height, radius, forceStrength, distance } = config;

    const graphGroup = svg.append("g");

    const teams = state.getTeams();
    const interactions = state.getInteractions();

    // Prepare nodes
    const nodes = teams.map((team) => ({
        id: team.shortName,
        name: team.fullName,
        shortName: team.shortName,
        x: Math.random() * (width - 2 * radius) + radius,
        y: Math.random() * (height - 2 * radius) + radius
    }));

    // Prepare links and identify differences
    const links = [];
    const differences = [];
    const processedLinks = new Set();

    for (let i = 0; i < teams.length; i++) {
        for (let j = i + 1; j < teams.length; j++) {
            const team1 = teams[i].shortName;
            const team2 = teams[j].shortName;
            const key = `${team1}-${team2}`;
            if (!processedLinks.has(key)) {
                const interaction1 = interactions[team1]?.[team2] || 'none';
                const interaction2 = interactions[team2]?.[team1] || 'none';
                if (interaction1 !== 'none' || interaction2 !== 'none') {
                    links.push({
                        source: team1,
                        target: team2,
                        type1: interaction1,
                        type2: interaction2
                    });
                    if (interaction1 !== interaction2) {
                        differences.push({
                            team1: teams[i], team2: teams[j],
                            type1: interaction1, type2: interaction2
                        });
                    }
                }
                processedLinks.add(key);
            }
        }
    }

    // Initialize D3 force simulation
    const simulation = d3.forceSimulation(nodes)
        .force("link", d3.forceLink(links).id(d => d.id).distance(distance))
        .force("charge", d3.forceManyBody().strength(forceStrength))
        .force("center", d3.forceCenter(width / 2, height / 2))
        .force("collision", d3.forceCollide().radius(radius + 10)); // Ensure nodes don’t overlap

    // Draw links
    const link = graphGroup.append("g")
        .selectAll("g")
        .data(links)
        .join("g");

    link.append("line")
        .each(function(d) {
            handleDisagreement(d3.select(this), d.type1, d.type2);
        });

    // Draw nodes
    const node = graphGroup.append("g")
        .selectAll("g")
        .data(nodes)
        .join("g")
        .call(d3.drag()
            .on("start", (event, d) => {
                if (!event.active) simulation.alphaTarget(0.3).restart();
                d.fx = d.x;
                d.fy = d.y;
            })
            .on("drag", (event, d) => {
                d.fx = event.x;
                d.fy = event.y;
            })
            .on("end", (event, d) => {
                if (!event.active) simulation.alphaTarget(0);
                d.fx = null;
                d.fy = null;
            })
        );

    node.append("circle")
        .attr("r", radius)
        .attr("fill", "#e0e0e0")
        .attr("stroke", "#000")
        .attr("stroke-width", 2);

    node.append("text")
        .text(d => d.shortName)
        .attr("text-anchor", "middle")
        .attr("dy", "0.3em")
        .attr("font-family", "Arial, sans-serif")
        .attr("font-size", "14px");

    // Add icons for interaction types
    const iconGroups = graphGroup.append("g")
        .selectAll("g")
        .data(links)
        .join("g");

    iconGroups.each(function(d) {
        createIconGroup(d3.select(this), d.type1, d.type2);
    });

    // Simulation tick event
    simulation.on("tick", () => {
        link.selectAll("line")
            .attr("x1", d => d.source.x)
            .attr("y1", d => d.source.y)
            .attr("x2", d => d.target.x)
            .attr("y2", d => d.target.y);

        node.attr("transform", d => `translate(${d.x},${d.y})`);

        iconGroups.attr("transform", d => `translate(${(d.source.x + d.target.x) / 2},${(d.source.y + d.target.y) / 2})`);
    });

    // Display differences in interaction perceptions
    const differenceList = document.getElementById('differenceList');
    if (differenceList) {
        differenceList.innerHTML = differences.length > 0 ?
            `<h3 class="text-xl font-semibold mb-2">Interaction Differences:</h3>` +
            differences.map(diff => `
                <p>${diff.team1.fullName} (${diff.team1.shortName}) sees ${diff.team2.fullName} (${diff.team2.shortName}) as ${diff.type1}, but ${diff.team2.fullName} (${diff.team2.shortName}) sees ${diff.team1.fullName} (${diff.team1.shortName}) as ${diff.type2}.</p>
            `).join('') :
            '<p>No differences detected.</p>';
    }
}
