import { state } from './state.js';
import { drawGraph } from './graphManager.js';
import { getSvgIcon } from './iconManager.js';

const interactionTypes = [
    { value: 'none', label: 'None', color: '#999999', icon: '' },
    { value: 'collaboration', label: 'Collaboration', color: '#FF6B6B', icon: 'collaboration' },
    { value: 'x-as-a-service', label: 'X-as-a-Service', color: '#4ECDC4', icon: 'x-as-a-service' },
    { value: 'facilitation', label: 'Facilitation', color: '#FFA500', icon: 'facilitation' }
];

export function renderTeamList() {
    const teams = state.getTeams();
    const teamList = document.getElementById('teamList');
    teamList.innerHTML = teams.map(team => `
        <div class="team-card">
            <div class="flex justify-between items-start">
                <div>
                    <h3>
                        <span class="team-full-name">${team.fullName}</span>
                        <span class="team-short-name">(${team.shortName})</span>
                    </h3>
                    <p class="team-description">${team.description || 'No description provided'}</p>
                </div>
                <button class="remove-team-btn text-red-500 hover:text-red-700 transition-colors duration-200" data-team="${team.shortName}">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </button>
            </div>
        </div>
    `).join('');

    teamList.querySelectorAll('.remove-team-btn').forEach(button => {
        button.addEventListener('click', () => {
            const teamShortName = button.dataset.team;
            const teamToRemove = teams.find(team => team.shortName === teamShortName);
            if (confirm(`Are you sure you want to remove the team "${teamToRemove.fullName} (${teamToRemove.shortName})"?`)) {
                state.removeTeam(teamShortName);
                renderTeamList();
                updateTeamSelect();
            }
        });
    });

    updateTeamSelect();
}

function updateTeamSelect() {
    const teams = state.getTeams();
    const teamSelect = document.getElementById('teamSelect');
    const currentSelection = teamSelect.value;
    teamSelect.innerHTML = teams.map(team => `<option value="${team.shortName}">${team.fullName} (${team.shortName})</option>`).join('');
    if (teams.some(team => team.shortName === currentSelection)) {
        teamSelect.value = currentSelection;
    } else if (teams.length > 0) {
        teamSelect.value = teams[0].shortName;
    }
    renderInteractionList();
}

export function renderInteractionList() {
    const teams = state.getTeams();
    const interactions = state.getInteractions();
    const selectedTeam = document.getElementById('teamSelect').value;
    const interactionList = document.getElementById('interactionList');

    interactionList.innerHTML = teams
        .filter(team => team.shortName !== selectedTeam)
        .map(team => {
            const currentInteraction = interactions[selectedTeam] && interactions[selectedTeam][team.shortName]
                ? interactions[selectedTeam][team.shortName]
                : 'none';

            return `
                <div class="interaction-item">
                    <div class="team-info">
                        <span class="text-gray-800 font-medium">${team.fullName} (${team.shortName})</span>
                    </div>
                    <div class="interaction-buttons" data-team1="${selectedTeam}" data-team2="${team.shortName}">
                        ${interactionTypes.map(type => `
                            <button class="interaction-btn ${currentInteraction === type.value ? 'active' : ''}"
                                    data-type="${type.value}">
                                ${getSvgIcon(type.icon)}
                                <span>${type.label}</span>
                            </button>
                        `).join('')}
                    </div>
                </div>
            `;
        }).join('');

    interactionList.querySelectorAll('.interaction-buttons').forEach(buttonGroup => {
        buttonGroup.addEventListener('click', (e) => {
            if (e.target.closest('.interaction-btn')) {
                const button = e.target.closest('.interaction-btn');
                const type = button.dataset.type;
                const team1 = buttonGroup.dataset.team1;
                const team2 = buttonGroup.dataset.team2;
                state.updateInteraction(team1, team2, type);

                buttonGroup.querySelectorAll('.interaction-btn').forEach(btn => btn.classList.remove('active'));
                button.classList.add('active');
            }
        });
    });
}

export function showStep(stepId) {
    document.querySelectorAll('.step-content').forEach(step => step.classList.add('hidden'));
    document.getElementById(stepId).classList.remove('hidden');

    document.querySelectorAll('.btn-nav').forEach(btn => btn.classList.remove('active'));
    document.querySelector(`.btn-nav[data-step="${stepId}"]`).classList.add('active');

    if (stepId === 'step2') {
        renderInteractionList();
    } else if (stepId === 'step3') {
        drawGraph();
    }
}

// Initialize event listeners
document.addEventListener('DOMContentLoaded', () => {
    document.getElementById('addTeamForm').addEventListener('submit', (e) => {
        e.preventDefault();
        const fullNameInput = document.getElementById('teamFullName');
        const shortNameInput = document.getElementById('teamShortName');
        const descriptionInput = document.getElementById('teamDescription');

        if (fullNameInput && shortNameInput) {
            const fullName = fullNameInput.value.trim();
            const shortName = shortNameInput.value.trim();
            const description = descriptionInput ? descriptionInput.value.trim() : '';

            if (fullName && shortName) {
                if (state.addTeam(fullName, shortName, description)) {
                    fullNameInput.value = '';
                    shortNameInput.value = '';
                    if (descriptionInput) descriptionInput.value = '';
                    showNotification('Team added successfully!', 'success');
                } else {
                    showNotification('A team with this short name already exists.', 'error');
                }
            }
        } else {
            console.error('Team input fields not found');
        }
    });

    document.querySelectorAll('.btn-nav').forEach(btn => {
        btn.addEventListener('click', () => showStep(btn.dataset.step));
    });

    document.getElementById('teamSelect').addEventListener('change', renderInteractionList);

    document.getElementById('toggleDifferences').addEventListener('click', () => {
        const differenceList = document.getElementById('differenceList');
        const toggleButton = document.getElementById('toggleDifferences');
        if (differenceList.classList.contains('hidden')) {
            differenceList.classList.remove('hidden');
            toggleButton.textContent = 'Hide Differences';
        } else {
            differenceList.classList.add('hidden');
            toggleButton.textContent = 'Show Differences';
        }
    });

    const resetButton = document.getElementById('resetButton');
    resetButton.addEventListener('click', () => {
        if (confirm('Are you sure you want to reset all data? This action cannot be undone.')) {
            state.reset();
            renderTeamList();
            showNotification('All data has been reset.', 'success');
        }
    });

    // Add Download State button
    const downloadButton = document.createElement('button');
    downloadButton.textContent = 'Download State';
    downloadButton.id = 'downloadButton';
    downloadButton.className = 'btn-nav ml-2';
    downloadButton.addEventListener('click', () => {
        const stateData = {
            source_url: 'www.team-interactions.app',
            teams: state.getTeams(),
            interactions: state.getInteractions()
        };
        const stateJson = JSON.stringify(stateData, null, 2);
        const blob = new Blob([stateJson], {type: 'application/json'});
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'team-interactions-state.json';
        a.click();
        URL.revokeObjectURL(url);
    });

    // Add Upload State button and functionality
    const uploadButton = document.createElement('button');
    uploadButton.textContent = 'Upload State';
    uploadButton.id = 'uploadButton';
    uploadButton.className = 'btn-nav ml-2';

    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'application/json';
    fileInput.style.display = 'none';

    uploadButton.addEventListener('click', () => fileInput.click());

    fileInput.addEventListener('change', (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const uploadedState = JSON.parse(e.target.result);
                    if (uploadedState.source_url === 'www.team-interactions.app' &&
                        uploadedState.teams && uploadedState.interactions) {
                        state.setTeams(uploadedState.teams);
                        state.setInteractions(uploadedState.interactions);
                        renderTeamList();
                        renderInteractionList();
                        showNotification('State uploaded successfully!', 'success');
                    } else {
                        throw new Error('Invalid state file');
                    }
                } catch (error) {
                    showNotification('Error uploading state: Invalid file format', 'error');
                }
            };
            reader.readAsText(file);
        }
    });

    // Create a container for the buttons
    const buttonContainer = document.createElement('div');
    buttonContainer.className = 'flex justify-center mt-8';

    // Move the reset button into the container instead of replacing it
    resetButton.parentNode.insertBefore(buttonContainer, resetButton);
    buttonContainer.appendChild(downloadButton);
    buttonContainer.appendChild(uploadButton);
    buttonContainer.appendChild(resetButton);
    document.body.appendChild(fileInput);

    renderTeamList();
});

function showNotification(message, type) {
    const notification = document.createElement('div');
    notification.textContent = message;
    notification.className = `fixed bottom-4 right-4 px-4 py-2 rounded-md text-white ${type === 'success' ? 'bg-green-500' : 'bg-red-500'}`;
    document.body.appendChild(notification);
    setTimeout(() => {
        notification.remove();
    }, 3000);
}
