// state.js

class State {
    constructor() {
        this.teams = [];
        this.interactions = {};
        this.listeners = [];
        this.loadFromStorage();
    }

    // Team management
    addTeam(fullName, shortName, description) {
        if (!this.teams.some(team => team.shortName === shortName)) {
            this.teams.push({ fullName, shortName, description });
            this.notifyListeners();
            this.saveToStorage();
            return true;
        }
        return false;
    }

    removeTeam(shortName) {
        this.teams = this.teams.filter(team => team.shortName !== shortName);
        delete this.interactions[shortName];
        Object.keys(this.interactions).forEach(t => {
            delete this.interactions[t][shortName];
        });
        this.notifyListeners();
        this.saveToStorage();
    }

    getTeams() {
        return [...this.teams];
    }

    // Interaction management
    updateInteraction(team1, team2, interactionType) {
        if (!this.interactions[team1]) this.interactions[team1] = {};
        this.interactions[team1][team2] = interactionType;
        this.notifyListeners();
        this.saveToStorage();
    }

    getInteractions() {
        return JSON.parse(JSON.stringify(this.interactions));
    }

    // Listener management
    addListener(listener) {
        this.listeners.push(listener);
    }

    notifyListeners() {
        this.listeners.forEach(listener => listener());
    }

    // Data persistence
    loadFromStorage() {
        try {
            const savedTeams = localStorage.getItem('teams');
            const savedInteractions = localStorage.getItem('interactions');
            if (savedTeams) {
                this.teams = JSON.parse(savedTeams).map(team => {
                    // Handle legacy data
                    if (typeof team === 'string') {
                        return { fullName: team, shortName: team.substring(0, 5), description: '' };
                    }
                    return team;
                });
            }
            if (savedInteractions) this.interactions = JSON.parse(savedInteractions);
        } catch (error) {
            console.error('Error loading data from storage:', error);
        }
    }

    saveToStorage() {
        try {
            localStorage.setItem('teams', JSON.stringify(this.teams));
            localStorage.setItem('interactions', JSON.stringify(this.interactions));
        } catch (error) {
            console.error('Error saving data to storage:', error);
        }
    }

    // Bulk operations
    setTeams(teams) {
        this.teams = teams;
        this.notifyListeners();
    }

    setInteractions(interactions) {
        this.interactions = interactions;
        this.notifyListeners();
    }

    // Reset
    reset() {
        this.teams = [];
        this.interactions = {};
        localStorage.removeItem('teams');
        localStorage.removeItem('interactions');
        this.notifyListeners();
    }
}

export const state = new State();
